import React from 'react'
import { useClasses, useQueryParam } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CTA from '../components/CTA'
import '../styles/Hero'
import '../styles/TwoCols'
import '../styles/ToggleButton'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql, Link } from "gatsby"

import HAPPY from '../images/migration/happy.svg'
import MAIL from '../images/migration/mail.svg'
import FILES from '../images/migration/files.svg'
import USERS from '../images/migration/users.svg'
import LOCAL from '../images/migration/local.svg'
import GOOGLE from '../images/migration/google.svg'
import MS from '../images/migration/microsoft.svg'

const STRINGS = {
  local: {
    'drive': 'Local',
    'mail-title': 'Self-hosted email',
    'files-title': 'Copying to the Cloud',
  },
  ms: {
    'drive': 'OneDrive',
    'mail-title': 'Migrating from MS365 email',
    'files-title': 'Migrating from OneDrive / SharePoint'
  },
  google: {
    'drive': 'Google Drive',
    'mail-title': 'Migrating from Gmail',
    'files-title': 'Migrating from Google Drive'
  }
}

export default function MigrationPage() {
  const root = useClasses()
  const [source, setSource] = useQueryParam('source','ms')
  const { heroImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(
          absolutePath: { glob: "**/images/migration/hero.jpg" }
          sourceInstanceName: { eq: "images" }   
        ) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout full dark>
      <SEO title='Migration' image={heroImage.childImageSharp.fluid.src}/>
      <main className='main' ref={root}>
        <BackgroundImage className='hero-container' fluid={heroImage.childImageSharp.fluid}>
          <div className='hero-overlay'/>
          <div className='hero-inner'>
            <div className='hero-text'>
              <div className='hero-main'>Just A Step ...</div>
              <div className='hero-slanted'>Till You Soar</div>
            </div>
          </div>
        </BackgroundImage>

        <div className='container'>
          <div className="heading-separator"/>
          <h1 className="title">Migration ...</h1>
          <h2 className="subtitle">is Just One or Two Steps</h2>
          <div className="description">
            <p>
              Who said migration has to be hard. Why should we be locked-in to a vendor when we are ready to move on!
            </p>
            <p>
              With our soft-migration approach, you can slowly move in, or do it overnight. It's up to you.
              Your data is 100% replicated over so you don't have to worry about reorganizing your files or emails.
            </p>
          </div>

          <div className='bg-gradient-to-br from-blue-500 to-cyan-500 shadow py-6 md:py-15 my-10 rounded-xl'>
            <div className="flex flex-col items-center justify-center">
              <h2 className="text-xl md:text-2xl xl:text-4xl font-bold text-white mb-5 md:mb-7">I am migrating from ...</h2>
              <div className="toggle-button-group" role="group">
                <button
                  type="button"
                  onClick={() => setSource('ms')}
                  className={"toggle-button" + (source == 'ms' ? ' active' : '')}
                >
                  <img className='w-8 h-8 inline md:-mt-1' src={MS}/>
                  <span className='hidden md:inline md:pl-2'>MS365</span>
                </button>
                <button
                  type="button"
                  onClick={() => setSource('local')}
                  className={"toggle-button" + (source == 'local' ? ' active' : '')}
                >
                  <img className='w-8 h-8 inline md:-mt-1' src={LOCAL}/>
                  <span className='hidden md:inline md:pl-2'>Local</span>
                </button>
                <button
                  type="button"
                  onClick={() => setSource('google')}
                  className={"toggle-button" + (source == 'google' ? ' active' : '')}
                >
                  <img className='w-8 h-8 inline md:-mt-1' src={GOOGLE}/>
                  <span className='hidden md:inline md:pl-2'>Google</span>
                </button>              
              </div>
            </div>
          </div>

          { source == 'ms' ?
            <div className="twocols mt-10">
              <div className="twocols-col-image">
                <img src={HAPPY}/>
              </div>
              <div className="twocols-col-text-left">
                <div className="subheading">One-Click Migration</div>
                <div className="heading">MS 365 to OneOffice Migration App</div>
                <div className="description">
                  <p>
                    We created the industry's first <strong>one-click migration</strong> application from MS 365 to another platform!
                    You shouldn't keep using your platfrom just because you are locked in!
                  </p>
                  <p>
                    Migrates automatically your users, emails, folders, files, calendars, contacts and even internal shares.
                    Just head over to <Link to='/ms365'><strong>the migration app</strong></Link> to get started!
                  </p>
                </div>
              </div>
            </div> :
            <>
              <div className="twocols mt-10">
                <div className="twocols-col-image">
                  <img src={USERS}/>
                </div>
                <div className="twocols-col-text-left">
                  <div className="subheading">#1 - Users Migration</div>
                  <div className="heading">Automated Account Provisioning</div>
                  <div className="description">
                    <p>
                      All you need is an Excel file with first name, last name, email, password and any groups you want your users to belong to.
                    </p>
                    <p>
                      An automated email will onboard your users, as well as startup material they will find when they first log in.
                    </p>
                    <p>
                      If you haven't already done so, head over to our <a href="https://docs.oneoffice.ca" target="_blank">Docs site</a> where your users will find all they need to get started.
                    </p>
                  </div>
                </div>
              </div>

              <div className="twocols">
                <div className="twocols-col-image">
                  <img src={MAIL}/>
                </div>
                <div className="twocols-col-text-left">
                  <div className="subheading">#2 - EMail Migration</div>
                  <div className="heading">{STRINGS[source]['mail-title']}</div>
                  <div className="description">
                    <p>Email migration is a breeze. We have an online email migration tool that transfers all your emails over to your OO email (including headers, attachments, etc.).</p>
                    <p className="quote">Email migration even tranfers all the folders you created, so things stay the way you organized them!</p>
                    <p>Depending on the size of your mailboxes, it may take an hour or a weekend.</p>
                  </div>
                </div>
              </div>


              <div className="twocols">
                <div className="twocols-col-image">
                  <img src={FILES}/>
                </div>
                <div className="twocols-col-text-right">
                  <div className="subheading">#3 - Files Migration</div>
                  <div className="heading">{STRINGS[source]['files-title']}</div>
                  <div className="description">
                    { source == 'local' ?
                      <>
                        <p>Moving your files over to the cloud is as simple as dragging your folders over to your browser.</p>
                        <p>The built-in smart uploader will copy each folder and its content over painlessly.</p>
                        <p>Depending on your connection, you can transfer over 100G of data overnight, so you hit the ground running the next morning.</p>
                      </> :
                      <>
                        <p>A connector for {STRINGS[source]['drive']} automatically pulls all your files over to your OneOffice files.</p>
                        <p>Folders, files, videos, documents are all copied over automatically in sequence so you get a fully functional copy of your data that is good-to-go!</p>
                      </>
                    }
                  </div>
                </div>
              </div>
            </>
          }

          <CTA variant={4}/>
        </div>
      </main>
    </Layout>
  )
}
